.file {
  position: relative;
  display: inline-block;
  margin-left: 20px;
}
.file label {
  display: block;
  width: 50px;
  background: #00aa13;
  color: #fff;
  font-size: 30px;
  line-height: 50px;
  cursor: pointer;
  text-align: center;
}
.fileInput {
  display: none;
}
