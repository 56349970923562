@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-tabs .nav-link.active {
  color: #00aa13;
  border-color: #00aa13;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("https://cdn-icons-png.flaticon.com/512/5611/5611992.png");
}
.accordion-button::after {
  background-image: url("https://cdn-icons-png.flaticon.com/512/5611/5611992.png");
}
.custom-scroll::-webkit-scrollbar {
  width: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #00aa13;
  height: 150px;
  border-radius: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: darkgray;
}

.css-1bm3rsl-MuiButtonBase-root-MuiFab-root:hover {
  background-color: #01500b !important;
}

.css-1bm3rsl-MuiButtonBase-root-MuiFab-root {
  box-shadow: none !important;
}
.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  background-color: #00aa13 !important;
}
